<div *ngIf="user" class="layout-topbar">
  <a class="app-logo" routerLink="/dashboard">
    <img alt="app logo" [src]="'assets/logos/logo.png'" />
    <!-- <span class="app-name">Verona</span> -->
  </a>

  <button
    #menubutton
    class="topbar-menubutton p-link"
    type="button"
    (click)="onMenuButtonClick()"
  >
    <span></span>
  </button>

  <ul class="topbar-menu">
    <li *ngFor="let item of tabs; let i = index">
      <a
        [routerLink]="item.routerLink"
        routerLinkActive="active-route"
        [routerLinkActiveOptions]="
          item.routerLinkActiveOptions || {
            paths: 'exact',
            queryParams: 'ignored',
            fragment: 'ignored',
            matrixParams: 'ignored'
          }
        "
        [fragment]="item.fragment"
        [queryParamsHandling]="item.queryParamsHandling"
        [preserveFragment]="item.preserveFragment!"
        [skipLocationChange]="item.skipLocationChange!"
        [replaceUrl]="item.replaceUrl!"
        [state]="item.state"
        [queryParams]="item.queryParams"
      >
        <span>{{ item.label }}</span>
      </a>
      <i class="pi pi-times" (click)="removeTab($event, item, i)"></i>
    </li>
    <li *ngIf="!tabs || tabs.length === 0" class="topbar-menu-empty"></li>
  </ul>
  
  <div class="topbar-profile">
    <button
      class="topbar-profile-button p-link"
      type="button"
      pStyleClass="@next"
      enterClass="hidden"
      enterActiveClass="scalein"
      leaveToClass="hidden"
      leaveActiveClass="fadeout"
      [hideOnOutsideClick]="true"
    >
      @defer(){
        <img
          style="border-radius: 50%; object-fit: cover"
          alt="avatar"
          [src]="
            user?.avatar
              ? user?.avatar
              : 'https://ionicframework.com/docs/img/demos/avatar.svg'
          "
        />
      }

      <span class="profile-details">
        <span class="profile-name">{{ user?.name }}</span>
        <span class="profile-job">{{ user?.email }}</span>
      </span>
      <i class="pi pi-angle-down"></i>
    </button>
    <ul
      class="list-none p-3 m-0 border-round shadow-2 hidden absolute surface-overlay origin-top w-full sm:w-12rem mt-2 right-0 top-auto"
    >
      <li>
        <a
          pRipple
          (click)="logout()"
          class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
        >
          <i class="pi pi-power-off mr-3"></i>
          <span>Cerrar sesión</span>
        </a>
      </li>
    </ul>
  </div>
</div>
